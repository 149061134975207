<template>
  <section id="section-news-post" class="min-vh-100">
    <div class="container-fluid">
      <div class="row mx-0 align-items-center py-3 py-lg-4">
        <div class="col-12 col-lg text-center">
          <router-link to="/news/inicio"><img src="@/assets/mbd.png" alt="Logo"></router-link>
        </div>
        <h2 class="col-12 col-lg-8 text-center text-white"> Notícias </h2>
        <div class="d-none d-lg-block col text-center"></div>
      </div>
      <div class="row mx-0">
        <div class="d-none d-lg-block-1 col mx-2 rounded social bg"> Instagram </div>
        <div id="content" class="col-lg-8 mx-auto px-0 rounded bg">
          <router-view />
        </div>
        <div class="d-none d-lg-block-1 col mx-2 rounded social bg">  </div>
      </div>
      <div class="row mx-0 my-3 d-none d-lg-block-1">
        <div class="col mx-2 rounded midia bg"> Youtube </div>
      </div>
      <div class="row mx-0 my-3 justify-content-center">
        <p class="col-lg-auto text-center text-white mx-auto mx-lg-0 my-0"> MBD Informática -
          {{ new Date().getFullYear() }} </p>
        <p class="col-lg-auto text-center text-white mx-auto mx-lg-0 my-0"> CNPJ: 03.386.358/0001-92 </p>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        facebook: [],
      }
    },
    beforeMount(){
      // this.getFacebook();
    },
    methods:{
      getFacebook(){
        this.loading = true;
        this.$store.dispatch('getFacebook')
          .then((res) => this.facebook = res)
          .catch((error) => this.swal(false, error.data.mensagem, error))
          .finally(() => this.loading = false)
      }
    }
  }
</script>

<style scoped>
  #section-news-post {
    background: #000428;
    background: -webkit-linear-gradient(to bottom, #004e92, #000428);
    background: linear-gradient(to bottom, #004e92, #000428);
    overflow-x: hidden;
  }

  .bg {
    background: rgba(255, 255, 255, 0.75) !important;
  }

  .social {
    min-height: calc(100vh - 180px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .midia {
    height: calc(150px);
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media screen and (max-width: 769px) {
    #content {
      min-height: calc(100vh - 230px);
    }

    .social {
      min-height: calc(100vh - 230px);
      font-size: 80%;
    }
  }
</style>